<template>
  <section>
    <div v-if="loading">
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
    </div>
    <b-table-simple id="guides-table" responsive borderless fixed v-else>
      <thead>
        <tr v-for="(row, index) in rows" :key="index">
          <th
            v-show="index === 0"
            v-for="header in headers"
            :key="header"
            :id="header"
          >
            <CheckInput
              id="select-all-guides"
              :value="hasSelected"
              @change="handleCheckAll(!hasSelected)"
              tooltip="Selecionar todas as guias em elaboração"
              v-if="header === 'checkbox' && rows.length"
            />
            <b-row v-if="header !== 'checkbox'">
              <div>{{ header }}</div>
            </b-row>
          </th>
        </tr>
      </thead>
      <tbody v-for="(row, index) in rows" :key="index">
        <tr v-if="!hideRows">
          <td>
            <CheckInput
              v-if="row.situation === 'Em elaboração'"
              :value="selectedGuides.map(item => item.id).includes(row.id)"
              @change="selectGuidesCheck(row)"
              :disabled="!!row.sus_batch_id || (selectedCompetency && selectedCompetency !== row.competency)"
              :tooltip="rowCheckTooltipText(row)"
            />
          </td>
          <td>
            <span
              class="situation-tag"
              :style="`background: ${colors[row.situation]}`"
            >
              <section class="text-tag">
                {{ row.situation }}
              </section>
            </span>
          </td>
          <td>{{ parseDate(row.created_at) }}</td>
          <td>
            <section>
              {{ row.patient?.name }}
            </section>
          </td>
          <td>
            <section v-if="type === 'APAC'">
              {{ row.guide?.apac_authorization_number}}
            </section>
            <section v-if="type === 'AIH'">
              {{ row.guide?.aih_number}}
            </section>
            <section v-else>
              {{ row.guide?.first_authorization_number }}
            </section>
          </td>
          <td>
            {{ row.professional?.name }}
          </td>

          <td>
            {{ row.sus_batch?.number }}
          </td>
          <td>
            {{ row.guide?.guide_number }}
          </td>
          <td v-if="type === 'BPA'">
            {{ row.guide?.sheet }}
          </td>
          
          <td>
            {{ totalProcedures(row) }}
          </td>
          <td>
            {{ row.sus_batch?.status === SusBatch.BATCH_STATUS_FINISHED && row.sus_batch?.payment_date ? totalProcedures(row) : parseNumberToMoney(0) }}
          </td>
          <td v-if="requiredFields.guide_conference">
            <label class="checkbox ml-2">
              <input
                type="checkbox"
                v-model="row.conferred"
                @change="changeCheck(row)"
              />
              <span class="checkmark">
                <CheckIcon />
              </span>
            </label>
          </td>
          <td>
            <div class="actions">
              <!-- <Icon>
                <Clip
                  class="icon-gray"
                  @click="row.files_count ? openDocumentListModal(row) : openDocumentModal(row)"
                  v-b-tooltip.hover 
                  :title="row.files_count ? 'Abrir arquivos' : 'Anexar arquivos'"
                />
              </Icon> -->

              <Icon
                placement="left"
                :tooltip="
                  row.situation !== 'Finalizada'
                    ? 'Editar e ver detalhes'
                    : 'Ver Detalhes'"
              >
                <Edit class="icon" @click="getGuide(row, type)" />
              </Icon>

              <Icon>
                <Trash
                  class="icon trash"
                  @click="!row.sus_batch ? onDelete(row) : null"
                  v-b-tooltip.hover
                  :title="
                    !row.sus_batch
                      ? 'Remover guia'
                      : 'Não é possível excluir guia com lote'
                  "
                />
              </Icon>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <noFilterResults
      v-if="!rows?.length && !loading"
      message="Os filtros aplicados não geraram nenhum resultado."
    />
    
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'
import {
  getTypes,
  parseItemType
} from '@/modules/tiss/manageGuides/utils/guideHelper'
import {
  SusGuide,
  SusBatch
} from '@/modules/sus/manageGuides/utils/statuses'
import api from '@/modules/sus/api'

export default {
  name: 'GuideReportTable',
  components: {
    noFilterResults: () => import('@/components/General/noFilterResults'),
    CheckInput: () => import('@/components/General/Check'),
    CheckIcon: () => import('@/assets/icons/check.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Icon: () => import('@/components/General/Icon'),
    // Clip: () => import('@/assets/icons/clip2.svg'),
    // ApacGuideModal: () => import('@/components/Sus/Guides/ApacGuideModal'),
    // BpaGuideModal: () => import('@/components/Sus/Guides/BpaGuideModal'),
    // File: () => import('@/assets/icons/fileHistory.svg')
  },
  props: {
    rows: Array,
    getGuide: Function,
    openHistoric: Function,
    openDocumentModal: Function,
    openDocumentListModal: Function,
    status: String,
    addAndOpenGuide: Function,
    type: String,
    headers: Array,
    loading: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Boolean,
      default: false
    },
    hasSavedInvoiceId: {
      type: Boolean,
      default: false
    },
    filters: Object,
    allSelectableGuides: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      selectedGuides: [],
      watchAllSelected: true,
      tissGuide: {},
      colors: {
        [SusGuide.GUIDE_SITUATION_OPEN]: '#FCDEC2',
        [SusGuide.GUIDE_SITUATION_IN_PREPARATION]: '#FCDEC2',
        [SusGuide.GUIDE_SITUATION_PAYED]: 'var(--states-success-soft-green)',
        [SusGuide.GUIDE_SITUATION_PARTIALLY_PAID]: '#E7FFDB',
        [SusGuide.GUIDE_SITUATION_GLOSSED]: '#FED2CE',
        [SusGuide.GUIDE_SITUATION_AUTHORIZED]: '#E7ECFE;'
      },
      selectedType: null,
      hideRows: false,
      openedMenu: false,
      maxGuidesPerBatch: null,
      order: { created_at: null, patient_name: null },
      hasSelected: false,
      selectedGuide: null,
      susInformation: null,
      selectedCompetency: null,
      SusBatch
    }
  },
  computed: {
    ...mapState('manageGuidesSus', ['requiredFields']),            
    url() {
      return window.location.href
    },
    hasFilteredByHealthPlan() {
      return this.filters?.healthPlan !== null
    }
  },
  created(){
    this.getSusInformation();
  },
  methods: {
    getTypes,
    parseItemType,
    parseNumberToMoney,
    totalProcedures(row) {
      if(! row?.guide?.procedures?.length) return parseNumberToMoney(0)

      let total = 0
      row.guide.procedures.forEach(item => {
        total += item.total_value
      })

      return parseNumberToMoney(total)
    },
    async getSusInformation(){
      const isLoading = this.$loading.show()

      try {
        const { data } = await api.getInformationSusSettingByClinicId(
          this.clinic.id
        )
        this.susInformation = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    getSusGuideTotal(guide) {
      const total = this.getUnformattedSusGuideTotal(guide)
      return parseNumberToMoney(total)
    },
    getUnformattedSusGuideTotal(guide) {
      const total = guide.items.reduce((acc, item) => {
        acc += item.total_value
        return acc
      }, 0)
      return total
    },
    getSusGuidePaidTotal(guide) {
      const total = guide.items.reduce((acc, item) => {
        acc += item.value_paid
        return acc
      }, 0)
      return parseNumberToMoney(total)
    },
    rowCheckTooltipText(guide) {
      if (guide.situation !== SusGuide?.GUIDE_SITUATION_IN_PREPARATION) {
        return 'Esta guia já está em andamento!'
      }
      return 'Selecionar guia para lote'
    },
    unselectAllGuidesChecks() {
      this.selectedGuides = []
      this.selectedCompetency = null;
      this.$emit('selected-guides', this.selectedGuides)
    },
    selectGuidesCheck(row) {
      const index = this.selectedGuides.map(item => item.id).indexOf(row.id)

      if (index !== -1) {
        this.selectedGuides.splice(index, 1)
        if(this.selectedGuides.length === 0) this.selectedCompetency = null;
      } else {
        this.selectedGuides.push(row)
      }

      if(this.selectedGuides.length === 1) this.selectedCompetency = row.competency;

      this.$emit('selected-guides', this.selectedGuides)
      this.$emit('selected-competency', this.selectedCompetency)
    },
    handleCheckbox(value) {
      if (!value && this.selectAll) {
        this.watchAllSelected = false
        this.selectAll = false
        setTimeout(() => {
          this.watchAllSelected = true
        }, '1000')
      }
    },
    handleCheckAll(value) {
      if (value) {
        this.allSelectableGuides.forEach(id => {
          this.selectGuidesCheck({id: id})
        })
        return
      }
      this.unselectAllGuidesChecks()
    },
    async changeCheck(value) {
      const isLoading = this.$loading.show()
      try {
        await this.api.setConference(value.id, {
          conferred: value.conferred,
          clinic_id: this.clinic.id,
          guide_type: this.type
        })
        if (value.conferred) {
          this.$toast.success('Guia conferida com sucesso!')
        } else {
          this.$toast.success('Guia desmarcada com sucesso!')
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    rotateIcon($event) {
      const icon = $event.target
      icon.classList.toggle('rotate')
    },
    removeGuide(row) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover a Guia <span>${getTypes(
          row.guide_type
        )} - Nº ${row.provider_guide} do paciente ${
          row.patient.name
        }</span> da fatura atual?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit('onRemoveGuide', row)
        }
      })
    },
    onDelete(row) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir a Guia <span>${this.type}</span>? Esta ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit('onDeleteGuide', row)
        }
      })
    },
    handleOrder(key) {
      if (!this.order[key]) {
        this.order[key] = 'asc'
      } else if (this.order[key] === 'asc') {
        this.order[key] = 'desc'
      } else if (this.order[key] === 'desc') {
        this.order[key] = null
      }

      this.$emit('onOrdenate', this.order)
    }
  },
  watch: {
    hasSavedInvoiceId(value) {
      if (value) {
        this.unselectAllGuidesChecks()
        this.$emit('unselectedChecks')
      }
    },
    selectedGuides(value) {
      this.hasSelected = value.length > 0
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/table.scss" />

<style lang="scss" scoped>
#guides-table {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;
  margin: 5px 0;

  thead {
    font-weight: 600;
    color: var(--dark-blue);

    tr {
      background-color: var(--neutral-100);
    }
  }

  tbody {
    color: var(--type-active);
    font-weight: 600;
  }

  .spacing {
    height: 120px;
  }

  td {
    max-width: 350px !important;
  }

  td,
  th {
    @media (max-width: 1330px) {
      font-size: 12px;
    }
    .cursor-pointer {
      cursor: pointer;
    }

    .help-icon {
      width: 14px;
      margin-bottom: 4px;
    }
    .situation-tag {
      width: 100%;
      border-radius: 16px;
      padding: 5px 10px;
      background-color: var(--neutral-300);
      display: inline-block;
      text-align: center;
    }

    .text-tag {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }

    .toggle-btn {
      background: var(--blue-100);
      border: none;
      padding: 5px !important;

      .icon {
        width: 25px;
        transition: all 500ms;
      }
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .multi-content {
      display: flex;
      align-items: center;

      .multi-content-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .text-container {
          max-width: 10ch !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .popover-element-container {
        visibility: hidden !important;
        position: relative;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid var(--neutral-300);
        background-color: var(--neutral-000);
        // margin-top: 20px;
      }
    }

    .multi-content:hover {
      .popover-element-container {
        visibility: visible !important;
        p {
          max-width: 20ch !important;
        }
      }
    }

    .checkbox {
      margin-left: 25px !important;
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .options {
      cursor: pointer;
      width: 24px;
      height: 28px;
      position: relative;
      display: inline-block;

      .more-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        transition: all 0.5s;
        cursor: pointer;
      }

      .menu {
        z-index: 10;
        min-width: 180px;
        position: absolute;
        top: 28px;
        right: 0;
        background: var(--neutral-000);
        border: 1px solid var(--neutral-200);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
          0px 4px 10px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;
        padding: 0;
        opacity: 0;
        transition: all 0.5s;
        flex-direction: column;
        align-items: start;
        display: none;

        .mtb-12 {
          margin: 12px 0;
        }

        .btn {
          font-weight: 600;
          width: 100%;

          &.remove {
            color: var(--states-error);
          }

          &.btn-link {
            text-align: left;
            padding: 12px 18px !important;
          }
        }
      }

      &:hover {
        .more-icon {
          transform: rotate(90deg);
        }

        .menu {
          opacity: 1;
          display: flex;
          z-index: 10;
        }
      }
    }
  }

  .hide-btn {
    color: var(--blue-700);
    text-decoration: underline;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td:last-child {
    overflow: visible;
  }

  .collapsable-row {
    td {
      padding: 0;
      border-top: none;
      border-bottom: 0.5px dotted var(--neutral-300);
    }

    .collapse-table {
      width: 100%;
      margin: 0 auto 20px auto;

      thead {
        tr {
          border-radius: 8px !important;
          background-color: var(--neutral-200);

          th {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 16px;
          }

          th:nth-child(1) {
            border-top-left-radius: 8px;
          }

          th:last-child {
            border-top-right-radius: 8px !important;
          }
        }
      }

      tbody {
        border: 1px dashed var(--neutral-200);

        tr {
          td {
            padding: 15px 5px;
          }

          td:nth-child(4) {
            max-width: 35ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.trash {
      width: 20px;
      height: 20px;
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.trash:hover {
      stroke: var(--states-error);
    }

  }

  .icon-gray {
    cursor: pointer;
    stroke: var(--neutral-100);
  }
}
.checkbox {
  margin: 0 0 25px;
}
.text-align-center {
  text-align: center;
}

.rotate {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
